document.addEventListener("alpine:init", () => {
  Alpine.store("globalSearch", {
    id: null,
    culture: null,

    initialize(id, culture) {
      this.id = id;
      this.culture = culture;
      this.loadFiltersFromUrl();
      this.getFilters(id, culture);
      this.setupEventListeners();
    },

    setupEventListeners() {
      window.addEventListener("popstate", (event) => {
        this.loadFiltersFromUrl();
        this.fetchAndUpdateContent(window.location.href);
      });
    },

    /**  Add these new properties to your store */
    sort: false,
    sortItems: {
      Date: "Nyeste biler",
      Model: "Model",
      Brand: "Mærke",
      PriceLow: "Laveste pris",
      PriceHigh: "Højeste pris",
      Year: "Modelår",
      Mileage: "Kilometer",
    },

    /** Indicates if a request is in progress */
    isLoading: false,

    /** Indicates if a result change is in progress */
    resultsChanging: false,

    /** Object containing search parameters */
    search: {
      search: "",
      makes: [],
      models: [],
      propellants: [],
      leasingType: "",
      gear: null,
      types: [],
      isLeasing: true,
      isEngros: true,
      isNoTax: true,
      yellowPlates: null,
      maxTaxationBasis: 0,
    },

    /** Object to store filter data */
    filter: {},

    loadFiltersFromUrl() {
      const urlParams = new URLSearchParams(window.location.search);
  
      // Update only specific properties to avoid overwriting existing ones
      this.search.search = urlParams.has('q') ? urlParams.get('q') : this.search.search;
      this.search.makes = urlParams.has('m') ? urlParams.get('m').split(',') : this.search.makes;
      this.search.models = urlParams.has('c') ? urlParams.get('c').split(',') : this.search.models;
      this.search.propellants = urlParams.has('p') ? urlParams.get('p').split(',') : this.search.propellants;
      this.search.leasingType = urlParams.has('lt') ? urlParams.get('lt') : this.search.leasingType;
      this.search.gear = urlParams.has('g') ? urlParams.get('g') : this.search.gear;
      this.search.types = urlParams.has('t') ? urlParams.get('t').split(',') : this.search.types;
      this.search.isLeasing = urlParams.has('l') ? urlParams.get('l') !== 'false' : this.search.isLeasing;
      this.search.isEngros = urlParams.has('e') ? urlParams.get('e') !== 'false' : this.search.isEngros;
      this.search.isNoTax = urlParams.has('n') ? urlParams.get('n') !== 'false' : this.search.isNoTax;
      this.search.yellowPlates = urlParams.has('y')
          ? (urlParams.get('y') === 'true' ? true : (urlParams.get('y') === 'false' ? false : null))
          : this.search.yellowPlates;
      this.search.sort = urlParams.has('s') ? urlParams.get('s') : this.search.sort;
  
      if (urlParams.has('lp')) this.search.maxLeasingPrice = parseInt(urlParams.get('lp'), 10);
      if (urlParams.has('tb')) this.search.maxTaxationBasis = parseInt(urlParams.get('tb'), 10);
  },
  
    /**
     * Fetches filters based on current search parameters
     * @param {string} id - The ID parameter for the request
     * @param {string} culture - The culture parameter for the request
     * @returns {Promise} A promise that resolves when the filters are fetched
     */
    getFilters(id = this.id, culture = this.culture) {
      if (!id || !culture) {
        console.error("ID or culture is not set");
        return Promise.reject("ID or culture is not set");
      }

      this.isLoading = true;
      return axios
        .post("/api/search/filters", this.search, {
          params: { id, culture },
        })
        .then((response) => {
          if (response.data && response.data.filters && response.data.search) {
            this.filter = { ...response.data.filters };
            this.search = { ...this.search };

          } else {
            console.error("Unexpected response format", response.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching filters:", error);
          if (error.response) {
            console.error(
              "Server responded with error:",
              error.response.status
            );
            console.error("Error data:", error.response.data);
          } else if (error.request) {
            console.error("No response received from server");
          } else {
            console.error("Error setting up the request:", error.message);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getSelectedItemsJoined(arrayName, defaultText = "Select items") {
      const array = this.search[arrayName];
      return array && array.length > 0 ? array.join(", ") : defaultText;
    },

    setSearch(initSearch) {
      Object.assign(this.search, initSearch);
    },

    searchQuery() {
      var queryObject = {
        q: this.search.search,
        m: this.search.makes,
        c: this.search.models,
        p: this.search.propellants,
        g: this.search.gear,
        t: this.search.types,
        lt: this.search.leasingType,
        lp: this.search.maxLeasingPrice,
        tb: this.search.maxTaxationBasis,
        l: this.search.isLeasing,
        e: this.search.isEngros,
        n: this.search.isNoTax,
        y: this.search.yellowPlates,
        s: this.search.sort,
      };

      const queryString = "?" + this.objectToQueryString(queryObject);

      return queryString;
    },

    objectToQueryString(obj) {
      const keyValuePairs = [];
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];

          if ((value && value != "") || value === false) {
            if (Array.isArray(value)) {
              keyValuePairs.push(
                `${encodeURIComponent(key)}=${value.join(",")}`
              );
            } else {
              keyValuePairs.push(
                `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
              );
            }
          }
        }
      }
      return keyValuePairs.join("&");
    },

    clearSearch() {
      this.search = {
        search: "",
        makes: [],
        models: [],
        propellants: [],
        leasingType: "",
        gear: null,
        types: [],
        isLeasing: true,
        isEngros: true,
        isNoTax: true,
        yellowPlates: null,
      };

      return this.getFilters(this.id, this.culture);
    },

    submitSearch(url, includeQuery = true) {
      if (includeQuery) {
        const fullUrl = `${url}${this.searchQuery()}`;
        window.location.href = fullUrl;
      } else {
        window.location.href = url;
      }
    },

    /**
     * Submits the search by updating the URL using history.pushState and updating content without page reload
     * @param {string} url - The base URL to update
     * @param {boolean} [includeQuery=true] - Whether to include the query string in the URL
     */
    submitSearchWithPushState(url, includeQuery = true) {
      let fullUrl = url;
      if (includeQuery) {
        fullUrl = `${url}${this.searchQuery()}`;
      }

      // Update the URL without reloading the page
      history.pushState({}, "", fullUrl);

      // Fetch and update content
      this.fetchAndUpdateContent(fullUrl);
    },

    /**
     * Fetches new content and updates the page
     * @param {string} url - The URL to fetch content from
     */
    fetchAndUpdateContent(url) {
      this.resultsChanging = true;

      axios
        .get(url)
        .then((response) => {
          // Assuming the response contains HTML for the updated content
          const parser = new DOMParser();
          const doc = parser.parseFromString(response.data, "text/html");

          // Update the necessary parts of the page
          // This example assumes there's a main content area with id 'search-results'
          const newContent = doc.getElementById("search-result");
          if (newContent) {
            document.getElementById("search-result").innerHTML =
              newContent.innerHTML;

            // Dispatch the event to reinitialize galleries
            window.dispatchEvent(new Event("searchResultUpdated"));
          }
        })
        .catch((error) => {
          console.error("Error fetching updated content:", error);
        })
        .finally(() => {
          this.resultsChanging = false;
        });
    },
  });
});
