import axios from "axios";
window.axios = axios;

import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.start();

import "./fancybox.js";
import "./alpine.js";
import "./scripts.js";
import "./swiper.js";
import "./animations.js";
import "./animatedGallery.js";
import "./globalSearch.js";



